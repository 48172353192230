import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { ReactComponent as OpenIcon } from '../../assets/images/navigation/icon-open.svg';
import { ReactComponent as BasketIcon } from '../../assets/images/navigation/icon-basket.svg';
import { ReactComponent as AlarmBellIcon } from '../../assets/images/navigation/icon-alert-bell.svg';
import { ReactComponent as AlarmBellNoIcon } from '../../assets/images/navigation/icon-alert-bell_no.svg';
import { ReactComponent as BarcodeIcon } from '../../assets/images/navigation/icon-barcode.svg';

import Navbar from './Navbar';
import { orderAction } from '../../modules/orderSlice';
import { useSelector } from 'react-redux';
import { shopSelector } from '../../modules/shopSlice';
import { isMatchSpace } from '../../utils/space';

import { pushLogAction, pushLogSelector } from '../../modules/pushLogSlice';
import { customerSelector } from '../../modules/customerSlice';
import BarcodeModal from '../customer/modal/BarcodeModal';

const MainHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shopInfo = useSelector(shopSelector.shopInfo);

  const pushLogCnt = useSelector(pushLogSelector.cnt);
  const customerInfo = useSelector(customerSelector.customerInfo);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBarcodeOpen, setIsBarcodeOpen] = useState(false);

  const _isMenuOpen = useRef(isMenuOpen);

  const basketOrderItemList = useRef(JSON.parse(localStorage.getItem('basketOrderItemList')) || []);

  const basketGoodsLength = useMemo(() => {
    if (basketOrderItemList.current.length > 0) {
      let basketGoodsLength = 0;
      for (const basketOrderItem of basketOrderItemList.current) {
        basketGoodsLength += basketOrderItem.forCelcBeforePaymentData?.itemQty || 0;
      }
      return basketGoodsLength;
    } else {
      return 0;
    }
  }, []);

  const handleBarcodeModal = () => {
    setIsBarcodeOpen(true);
  };

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(isMenuOpen => !isMenuOpen);
  }, []);

  useEffect(() => {
    _isMenuOpen.current = isMenuOpen;
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMatchSpace(shopInfo.spaceId, 'SQI') || isMatchSpace(shopInfo.spaceId, 'KAKAO')) {
      dispatch(pushLogAction.cnt({ shopId: shopInfo.shopId, customerId: customerInfo.customerId }));
    }
  }, [customerInfo.customerId, dispatch, shopInfo.shopId, shopInfo.shopType, shopInfo.spaceId]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (isMatchSpace(shopInfo.spaceId, 'SQI') || isMatchSpace(shopInfo.spaceId, 'KAKAO')) {
        dispatch(pushLogAction.cnt({ shopId: shopInfo.shopId, customerId: customerInfo.customerId }));
      }
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [customerInfo.customerId, dispatch, shopInfo.shopId, shopInfo.shopType, shopInfo.spaceId]);

  useEffect(() => {
    const _handleMessage = e => {
      let message = {};
      try {
        message = JSON.parse(e.data);
      } catch (err) {
        return;
      }

      if (message.type === 'RES_NAV_BACK') {
        if (window.ReactNativeWebView) {
          if (_isMenuOpen.current) {
            setIsMenuOpen(false);
          } else {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQ_SHUTDOWN' }));
          }
        } else if (window.FlutterWebView) {
          if (_isMenuOpen.current) {
            setIsMenuOpen(false);
          } else {
            window.FlutterWebView.postMessage(JSON.stringify({ type: 'REQ_SHUTDOWN' }));
          }
        }
      } else if (message.type === 'RES_PUSH_MSG') {
        dispatch(
          orderAction.list({
            orderStartDt: dayjs().startOf('day').toISOString(),
            orderEndDt: dayjs().endOf('day').toISOString(),
          }),
        );
      } else if (message.type === 'RES_PUSH_CLICK') {
        if (message.url === 'order') {
          navigate('/order/list');
        } else if (message.url.includes('rating')) {
          const url = message.url;
          let orderId = '';
          const subIndex = url.indexOf('_');

          if(subIndex!=-1) {
            orderId = url.substring(subIndex + 1);
          }

          navigate('/order/rating', {
            state: {orderId: orderId}
          });
        } else if (message.url === 'survey') {
          navigate('/order/survey');
        } else {
          navigate('/');
        }
      }
    };

    if (window.ReactNativeWebView && navigator.appVersion.includes('Android')) {
      document.addEventListener('message', _handleMessage, true);
    } else {
      window.addEventListener('message', _handleMessage, true);
    }

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQ_PUSH_CLICK' }));
    } else if (window.FlutterWebView) {
      window.FlutterWebView.postMessage(JSON.stringify({ type: 'REQ_PUSH_CLICK' }));
    }

    return () => {
      if (window.ReactNativeWebView && navigator.appVersion.includes('Android')) {
        document.removeEventListener('message', _handleMessage, true);
      } else {
        window.removeEventListener('message', _handleMessage, true);
      }
    };
  }, [dispatch, navigate]);

  return (
    <>
      <HeaderBox>
        <Container>
          <button onClick={() => toggleMenu()}>
            <OpenIcon />
          </button>

          <Right>
            {customerInfo.barcodeYn === 'Y' && (
              <button style={{ backgroundColor: '#f6f6f6' }} onClick={() => handleBarcodeModal()}>
                <BarcodeIcon />
              </button>
            )}
            {shopInfo.shopType === 'FOODCOURT' && isMatchSpace(shopInfo.spaceId, 'KRAFTON') && (
              <>
                <button style={{ backgroundColor: '#f6f6f6' }} onClick={() => navigate('/customer/alert-center')}>
                  <AlarmBellIcon />
                </button>
              </>
            )}
            {(isMatchSpace(shopInfo.spaceId, 'SQI') || isMatchSpace(shopInfo.spaceId, 'KAKAO')) && (
              <>
                <button style={{ backgroundColor: '#f6f6f6' }} onClick={() => navigate('/customer/push-log')}>
                  {pushLogCnt > 0 ? <AlarmBellIcon /> : <AlarmBellNoIcon />}
                </button>
              </>
            )}

            <button style={{ backgroundColor: '#f6f6f6' }} className="basket-box" onClick={() => navigate('/basket/list')}>
              {basketGoodsLength > 0 && <span className="circle">{basketGoodsLength}</span>}
              <BasketIcon />
            </button>
          </Right>
        </Container>
      </HeaderBox>
      <BarcodeModal isOpen={isBarcodeOpen} setIsOpen={setIsBarcodeOpen} />
      <Navbar isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </>
  );
};

const Right = styled.div`
  display: flex;
  gap: 11px;
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.5rem;
  padding: 0 1.25rem;
  background: ${({ theme }) => theme.mainBackground};
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > button {
    background: none;
  }
  .basket-box {
    position: relative;
    margin-left: auto;
    .circle {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #000000;
      width: 1.6875rem;
      height: 1.6875rem;
      border-radius: 50%;
      right: -0.5rem;
      top: -0.375rem;
      font-size: 0.875rem;
      font-weight: bold;
      color: white;
    }
  }

  .name {
    color: #000000;
    font-style: normal;
    font-weight: bold;
    font-size: 1.0625rem;
  }
`;

export default React.memo(MainHeader);
